import React from 'react'
import { navigate } from 'gatsby-link'
import Layout from '../../components/Layout'

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export default class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isValidated: false }
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error))
  }

  render() {
    return (
      <Layout>

<section className="about-header">
  <div className="about-first">
    <div className="about-header-title">
      <h1 data-aos="fade" data-aos-delay="400" data-aos-duration="1000">Contact
      </h1>
      </div>
    </div>
    
  </section>
  

    <div className="contact-container">


      <div className="form-container">

        <div className="form-child">
        <form
      name="contact"
      method="post"
      action="/contact/thanks/"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      onSubmit={this.handleSubmit}
    >
      {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
      <input type="hidden" name="form-name" value="contact" />
      <div hidden>
        <label>
          Don’t fill this out:{' '}
          <input name="bot-field" onChange={this.handleChange} />
        </label>
      </div>
      <div className="field">
        <label className="label" htmlFor={'name'}>
          Your name
        </label>
        <div className="control">
          <input
            className="input"
            placeholder="Name"
            type={'text'}
            name={'name'}
            onChange={this.handleChange}
            id={'name'}
            required={true}
          />
        </div>
      </div>
      <div className="field">
        <label className="label" htmlFor={'email'}>
          Email
        </label>
        <div className="control">
          <input
            className="input"
            placeholder="Email"
            type={'email'}
            name={'email'}
            onChange={this.handleChange}
            id={'email'}
            required={true}
          />
        </div>
      </div>
      <div className="field">
        <label className="label" htmlFor={'message'}>
          Message
        </label>
        <div className="control">
          <textarea
            className="textarea"
            placeholder="Message"
            name={'message'}
            onChange={this.handleChange}
            id={'message'}
            required={true}
          />
        </div>
      </div>
      <div className="field righto">
        <button className="button is-link main-button" type="submit">
          Send
        </button>
      </div>
    </form>
        </div>



        <div className="contact-child"> 

          <div className="title">
            <h3>Contact Info</h3>
          </div>

          <div className="email">
            <div className="icon">
            <img src="../img/email.svg" alt="Location" />
            </div>
            <a href="mailto:info@zmlc.co.uk">
            <div className="text">
            info@zmlc.co.uk
            </div>
            </a>
          </div>

          <div className="phone">
          
            <div className="icon">
            <img src="../img/call.svg" alt="Location" />
            </div>
            <a href="tel:+442075296800">
            <div className="text">
            +44 (0)20 7529 6800
            </div>
            </a>
          </div>

          <div className="phone">
          
          <div className="icon">
          <img src="../img/location.svg" alt="Location" />
          </div>
          <div className="text">
          1 Hyde Way, Welwyn Garden City, AL7 3BU
          </div>
        </div>

        <div className="phone">
          
          <div className="icon">
          <img src="../img/location.svg" alt="Location" />
          </div>
          <div className="text">
          9 South Molton Street, Mayfair, London, W1K 5QH
          </div>
        </div>


        </div>

      </div>


      <div className="final-container">
      
      <div className="text">
        <h3>Get in touch with one of our dedicated key contacts.</h3>
      </div>

      <div className="contacts-grid">

      <div className="contact-item">
          <div className="name">
              <h2>John West</h2>
          </div>
          <a href="mailto:john@zmlc.co.uk">
            <div className="contacts-email">
            <div className="icon">
            <img src="../img/email.svg" alt="Location" />
            </div>
            <div className="address">
            john@zmlc.co.uk
            </div>
          </div>
          </a>
        </div>

        <div className="contact-item">
          <div className="name">
              <h2>Jeremy Fooks</h2>
          </div>
          <a href="mailto:jeremy@zmlc.co.uk">
          <div className="contacts-email">
            <div className="icon">
            <img src="../img/email.svg" alt="Location" />
            </div>
            <div className="address">
            jeremy@zmlc.co.uk
            </div>
          </div>
          </a>
        </div>

        <div className="contact-item">
          <div className="name">
              <h2>Fran Wimpress</h2>
          </div>
          <a href="mailto:fran@zmlc.co.uk">
          <div className="contacts-email">
            <div className="icon">
            <img src="../img/email.svg" alt="Location" />
            </div>
            <div className="address">
            fran@zmlc.co.uk 
            </div>
          </div>
          </a>
        </div>

        <div className="contact-item">
          <div className="name">
              <h2>Hannah West</h2>
          </div>
          <a href="mailto:hannah@zmlc.co.uk">
          <div className="contacts-email">
            <div className="icon">
            <img src="../img/email.svg" alt="Location" />
            </div>
            <div className="address">
            hannah@zmlc.co.uk
            </div>
          </div>
          </a>
        </div>

        <div className="contact-item">
          <div className="name">
              <h2>Urmil Shah</h2>
          </div>
          <a href="mailto:urmil@zmlc.co.uk">
          <div className="contacts-email">
            <div className="icon">
            <img src="../img/email.svg" alt="Location" />
            </div>
            <div className="address">
            urmil@zmlc.co.uk
            </div>
          </div>
          </a>
        </div>

        <div className="contact-item">
          <div className="name">
              <h2>Austin Wright</h2>
          </div>
          <a href="mailto:austin@zmlc.co.uk">
          <div className="contacts-email">
            <div className="icon">
            <img src="../img/email.svg" alt="Location" />
            </div>
            <div className="address">
            austin@zmlc.co.uk
            </div>
          </div>
          </a>
        </div>

      </div>
      
      </div>

      <div className="contact-page-map">
  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2482.9926151338937!2d-0.1496064842298162!3d51.51335147963615!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4876052c999a342b%3A0xcfa56bd8cc06ff0a!2s9%20S%20Molton%20St%2C%20Mayfair%2C%20London%20W1K%205QH!5e0!3m2!1sen!2suk!4v1618485888032!5m2!1sen!2suk"  allowfullscreen="" loading="lazy"></iframe>
    </div>


    </div>
      
      </Layout>
    )
  }
}
